import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useCallback } from 'react';

const auth_source = 'outlook' // 'outlook' or 'word'

class TokenHelper {

  handlePopupBlockedError(error: any) {
    if (
      error.errorCode === 'popup_window_error' ||
      error.errorCode === 'token_renewal_error' ||
      error.errorCode === 'interaction_in_progress' ||
      error.errorCode === 'user_cancelled' ||
      error.message.includes('popup')
    ) {
      this.showCustomAlert(
        'You are not signed in! Please sign in to continue.',
        () => {
          location.reload();
        }
      );
    }
  }

  hideCustomAlert() {
    const alertDiv = document.getElementById('customAlert');
    if (alertDiv) {
      document.body.removeChild(alertDiv);
    }
  }

  showCustomAlert(message: string, onRetry: () => void) {
    const alertDiv = document.createElement('div');
    alertDiv.id = 'customAlert';
    alertDiv.style.position = 'fixed';
    alertDiv.style.top = '50%';
    alertDiv.style.left = '50%';
    alertDiv.style.transform = 'translate(-50%, -50%)';
    alertDiv.style.padding = '20px';
    alertDiv.style.backgroundColor = '#fff';
    alertDiv.style.border = '1px solid #ccc';
    alertDiv.style.boxShadow = '0 2px 10px rgba(0, 0, 0, 0.1)';
    alertDiv.style.borderRadius = '8px';
    alertDiv.style.zIndex = '10000';
    alertDiv.style.textAlign = 'center';
    alertDiv.style.maxWidth = '300px';
    alertDiv.style.fontFamily = 'Arial, sans-serif';

    const alertMessage = document.createElement('p');
    alertMessage.innerText = message;
    alertMessage.style.marginBottom = '20px';
    alertMessage.style.color = '#333';
    alertMessage.style.fontSize = '16px';
    alertMessage.style.lineHeight = '1.4';
    alertDiv.appendChild(alertMessage);

    const alertButton = document.createElement('button');
    alertButton.innerText = 'Sign In';
    alertButton.style.padding = '10px 20px';
    alertButton.style.backgroundColor = '#0078d4';
    alertButton.style.color = '#fff';
    alertButton.style.border = 'none';
    alertButton.style.borderRadius = '4px';
    alertButton.style.cursor = 'pointer';
    alertButton.onclick = onRetry; // Updated to call onRetry directly
    alertButton.onmouseover = () => {
      alertButton.style.backgroundColor = '#005a9e';
    };
    alertButton.onmouseout = () => {
      alertButton.style.backgroundColor = '#0078d4';
    };
    alertDiv.appendChild(alertButton);

    document.body.appendChild(alertDiv);
  }

  getAccessToken(callback: (q_token?: string) => void, clear_tokens?: boolean): void {
    const q_id = localStorage.getItem('q_id');
    if (!q_id){
      const q_id = uuidv4();
      localStorage.setItem('q_id', q_id);
    }

    if (clear_tokens) {
      localStorage.removeItem('q_token');
      localStorage.removeItem('q_refresh');
    }

    const q_token = localStorage.getItem('q_token');
  
    const authCheck = async (q_token) => {
      try {
        const response = await axios.get(`${process.env.BACKEND_URL}/auth/check`, {
          headers: {
            Authorization: `Bearer ${q_token}`,
          },
        });

        const q_refresh = localStorage.getItem('q_refresh') || null;

        if (response.data === null && q_refresh) {
          
          console.log("Using q_refresh token to get new tokens");
          const response = await axios.get(`${process.env.BACKEND_URL}/auth/refresh`, {
            headers: {
              Authorization: `Bearer ${q_refresh}`,
              q_id: q_id,
            },
          });
          if (response.data){
            if (response.data.q_refresh){
              localStorage.setItem('q_refresh', response.data.q_refresh);
            };
            if (response.data.q_token){
              localStorage.setItem('q_token', response.data.q_token);
            };
          }
          return response.data
        }
        return response.data;
      } catch (error) {
        console.error('Error on authCheck:', error);
      }
    };

    const signinPopup = (callback: (q_token?: string) => void) => {
      const q_id = localStorage.getItem('q_id');
      const popupUrl = process.env.BACKEND_URL + '/signin?source=' + auth_source + '&q_id=' + q_id;
      const popupWindow = window.open(popupUrl, 'Popup', 'width=600,height=400');
      window.addEventListener('message', function (event) {
        if (event.source !== popupWindow || event.origin !== process.env.BACKEND_URL) {
          return;
        }
        const data = event.data;
        if (data.q_token) {
          localStorage.setItem('q_token', data.q_token);
          if (data.q_refresh) {
            localStorage.setItem('q_refresh', data.q_refresh);
          };
          callback(data.q_token);
        }
      }, false);
    };

    authCheck(q_token)
      .then(authCheckRes => {
        if (authCheckRes) {
          this.hideCustomAlert();
          const q_token = localStorage.getItem('q_token');
          callback(q_token);
        } else {
          this.showCustomAlert(
            'You are not signed in! Please sign in to continue.',
            () => {
              signinPopup((q_token) => {
                if (q_token) {
                  this.hideCustomAlert();
                  callback(q_token);
                } else {
                  console.error('Token not received');
                }
              });
            }
          );
        }
      })
      .catch(err => {
        console.log('Failed on authCheck:', err);
      });
  }
}

export const tokenHelper = new TokenHelper();