import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

interface MarkdownRendererProps {
    markdown: string;
}

const containerStyle: React.CSSProperties = {
    overflowY: 'auto',
    maxHeight: '100%',
    padding: '0.5em',
    boxSizing: 'border-box',
    lineHeight: '1.4',
    fontSize: '14px',
};

const baseStyle: React.CSSProperties = {
    margin: '0',
    padding: '0',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
};

const Text: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    if (typeof children === 'string') {
        return (
            <>
                {children.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && <br />}
                        {line}
                    </React.Fragment>
                ))}
            </>
        );
    }
    return <>{children}</>;
};

const components = {
    p: ({ children }: { children: React.ReactNode }) => (
        <p style={{ ...baseStyle, marginBottom: '0' }}><Text>{children}</Text></p>
    ),
    h1: ({ children }: { children: React.ReactNode }) => (
        <h1 style={{ ...baseStyle, fontSize: '1.5em', fontWeight: 'bold', marginTop: '0', marginBottom: '0' }}><Text>{children}</Text></h1>
    ),
    h2: ({ children }: { children: React.ReactNode }) => (
        <h2 style={{ ...baseStyle, fontSize: '1.3em', fontWeight: 'bold', marginTop: '0', marginBottom: '0' }}><Text>{children}</Text></h2>
    ),
    h3: ({ children }: { children: React.ReactNode }) => (
        <h3 style={{ ...baseStyle, fontSize: '1.1em', fontWeight: 'bold', marginTop: '0', marginBottom: '0' }}><Text>{children}</Text></h3>
    ),
    blockquote: ({ children }: { children: React.ReactNode }) => (
        <blockquote style={{ ...baseStyle, paddingLeft: '0.5em', borderLeft: '2px solid #ccc', marginBottom: '0' }}><Text>{children}</Text></blockquote>
    ),
    pre: ({ children }: { children: React.ReactNode }) => (
        <pre style={{ ...baseStyle, overflowX: 'auto', whiteSpace: 'pre-wrap', backgroundColor: '#f0f0f0', padding: '0.1em', marginBottom: '0' }}>{children}</pre>
    ),
    code: ({ children }: { children: React.ReactNode }) => (
        <code style={{ ...baseStyle, fontFamily: 'monospace', backgroundColor: '#f0f0f0', padding: '0.1em 0' }}>{children}</code>
    ),
    a: ({ href, children }: { href?: string; children: React.ReactNode }) => (
        <a href={href} target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'none' }}>
            {children}
        </a>
    ),
};

export const MarkdownChatRenderer: React.FC<MarkdownRendererProps> = ({ markdown }) => {
    console.log(markdown);
    return (
        <div style={containerStyle}>
            <ReactMarkdown
                components={components}
                rehypePlugins={[rehypeRaw]}
                skipHtml={false}
            >
                {markdown}
            </ReactMarkdown>
        </div>
    );
};
