import React from 'react';

interface BatteryProgressProps {
  value: number; // Progress value (0 to 1)
  height?: any; // Optional height input, e.g., "30px"
  width?: any; // Optional width input, e.g., "150px"
}

const BatteryProgressBar: React.FC<BatteryProgressProps> = ({
  value,
  height = '30px',
  width = '150px',
}) => {
  const segments = 10; // Total number of segments
  const segmentGap = '1px'; // Gap between each segment

  // Calculate width for each segment
  const segmentWidth = `calc((${width} - ${segments - 1} * ${segmentGap}) / ${segments})`;

  // Determine the color based on the value (similar to battery levels)
  const getBatteryColor = (value: number) => {
    if (value >= 0.75) {
      return '#6EC1E4'; // Light Blue for high levels
    } else if (value >= 0.5) {
      return '#89D0EF'; // Medium Light Blue
    } else if (value >= 0.25) {
      return '#A4DFF9'; // Pale Blue
    } else {
      return '#E5F3FA'; // Very Light Blue
    }
  };

  // Calculate the number of filled segments based on the progress
  const filledSegments = Math.min(Math.round(value * segments), segments); // Ensure filled segments do not exceed total segments

  // Battery terminal (small block on the right of the bar)
  const batteryTerminalStyles = {
    position: 'absolute' as 'absolute',
    right: '-6px',
    top: `calc(${height} / 4)`,
    height: `calc(${height} / 2)`,
    width: '6px',
    backgroundColor: 'black',
    borderRadius: '2px',
  };

  return (
    <div
      style={{
        position: 'relative',
        width,
        height,
        display: 'flex',
        backgroundColor: '#e9e9e9', // Light gray background for the entire battery bar
        borderRadius: '4px',
        padding: 0,
      }}
    >
      {[...Array(segments)].map((_, i) => (
        <div
          key={i}
          style={{
            width: segmentWidth,
            height: '100%',
            backgroundColor:
              i < filledSegments ? getBatteryColor(value) : '#e9e9e9', // Light gray for unfilled segments
            marginRight: i < segments - 1 ? segmentGap : '0px', // Reduced gap between segments
            borderRadius:
              i === 0
                ? '4px 0 0 4px'
                : i === segments - 1
                  ? '0 4px 4px 0'
                  : '0', // Rounded edges for first and last segment
          }}
        />
      ))}
      <div style={batteryTerminalStyles} />
    </div>
  );
};

export default BatteryProgressBar;
