import React, { useState } from 'react';
import {
  Button,
  makeStyles,
  shorthands,
  mergeClasses,
} from '@fluentui/react-components';
import { ArrowCircleLeft24Filled } from '@fluentui/react-icons';
import { formatDistanceToNow } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { AnnotationTask } from '../../model/annotation';
import { useMailboxContent } from '../../provider/MailboxContentProvider';
import { ActionEnum } from '../../model/base';

interface DebugPageProps {
  setShowDebugPage: (show: boolean) => void;
}

export const DebugPage: React.FC<DebugPageProps> = ({ setShowDebugPage }) => {
  const styles = useStyles();
  const { sendAnnotationTask, annotationTasks } = useMailboxContent();
  const [disabledButtons, setDisabledButtons] = useState<{
    [key: string]: boolean;
  }>({});

  const handleSendToQueue = (annotation: AnnotationTask) => {
    console.log('Sending annotation to queue', annotation);
    setDisabledButtons(prevState => ({
      ...prevState,
      [annotation.task_id]: true,
    }));
    sendAnnotationTask({
      task_id: annotation.task_id,
      task_type: annotation.task_type,
      action: ActionEnum.SendToAnnotationQueueRequest,
    });
  };

  const handleTrace = (annotation: AnnotationTask) => {
    console.log('Tracing annotation', annotation);
    // Add your tracing logic here
    const url = `https://smith.langchain.com/o/02bcc2ab-2d8c-4d9b-8e33-a33d2714599d/projects/p/58541fc9-b70e-4725-9547-e3488a3f3bd0?columnVisibilityModel=%7B%22feedback_stats%22%3Afalse%2C%22reference_example%22%3Afalse%2C%22metadata%22%3Atrue%7D&timeModel=%7B%22duration%22%3A%227d%22%7D&peek=${annotation.task_id}`;
    window.open(url, '_blank');
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div
          id="nav"
          className={styles.back}
          onClick={() => setShowDebugPage(false)}
        >
          <ArrowCircleLeft24Filled />
          <span className={styles.annotationText}>Analysis log</span>
        </div>
        <div className={styles.annotationList}>
          {annotationTasks
            .slice()
            .sort(
              (a, b) =>
                new Date(b.task_time).getTime() -
                new Date(a.task_time).getTime()
            )
            .slice(0, 5)
            .map((annotation: AnnotationTask) => {
              const localTime = toZonedTime(
                new Date(annotation.task_time),
                Intl.DateTimeFormat().resolvedOptions().timeZone
              );
              return (
                <div
                  key={annotation.task_id}
                  className={mergeClasses(
                    styles.annotationItem,
                    disabledButtons[annotation.task_id] && styles.disabled
                  )}
                >
                  <div className={styles.annotationDetails}>
                    <div>
                      <strong>Id:</strong> {annotation.task_id.slice(0, 16)}
                    </div>
                    <div>
                      <strong>Type:</strong> {annotation.task_type}
                    </div>
                    <div>
                      <strong>Time:</strong>{' '}
                      {formatDistanceToNow(localTime, { addSuffix: true })}
                    </div>
                  </div>
                  <div className={styles.buttonContainer}>
                    <Button onClick={() => handleTrace(annotation)}>
                      Open Tracing
                    </Button>
                    <Button
                      className={
                        disabledButtons[annotation.task_id]
                          ? styles.disabledButton
                          : ''
                      }
                      disabled={disabledButtons[annotation.task_id]}
                      onClick={() => handleSendToQueue(annotation)}
                    >
                      {disabledButtons[annotation.task_id]
                        ? 'Queued'
                        : 'Queue Review'}
                    </Button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    paddingTop: '16px',
    backgroundColor: 'white',
  },
  toolbarContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
  },
  back: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  } as const,
  content: {
    ...shorthands.margin('0', '20px'),
    marginTop: '12px',
  },
  annotationText: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginLeft: '8px',
  },
  annotationList: {
    display: 'flex',
    flexDirection: 'column',
  },
  annotationItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    marginBottom: '10px',
    backgroundColor: '#fff',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  annotationDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  disabled: {
    opacity: 0.5,
  },
  disabledButton: {
    backgroundColor: '#d3d3d3',
  },
});
