import * as React from 'react';
import { useState } from 'react';

import type { TabListProps } from '@fluentui/react-components';
import {
  makeStyles,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  Tooltip,
} from '@fluentui/react-components';
import {
  HistoryRegular,
  HomeRegular,
  MailTemplateRegular,
} from '@fluentui/react-icons';

import { MatterHistoryTab } from '../components/tab/MatterHistoryTab';
import { EmailDocsTab } from '../components/tab/EmailDocsTab';
import MainHomeTab from '../components/tab/MainHomeTab';
import BackgroundStatus from './BackgroundStatus';
import { useMailboxContent } from '../../provider/MailboxContentProvider';
import BatteryProgressBar from './misc/ProgressBar';
import { ActionEnum } from '../../model/base';

enum TabValue {
  MatterHistory = 'MatterHistory',
  EmailDocs = 'Email&Docs',
  MainHome = 'MainHome',
}
const convertDate = timestamp => {
  const date = new Date(timestamp);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so +1
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
};

const tabContent: Record<TabValue, { icon: JSX.Element; displayName: string }> =
  {
    [TabValue.MainHome]: {
      icon: <HomeRegular />,
      displayName: 'Home',
    },
    [TabValue.MatterHistory]: {
      icon: <HistoryRegular />,
      displayName: 'Matter History',
    },
    [TabValue.EmailDocs]: {
      icon: <MailTemplateRegular />,
      displayName: 'Email & Docs',
    },
  };

export const TabContainer: React.FC<Partial<TabListProps>> = () => {
  const styles = useStyles();
  const [selectedKey, setSelectedKey] = useState<TabValue>(TabValue.MatterHistory);
  const { userOfflineProcess, GetUserProcess } = useMailboxContent();

  const handleTabChange = (
    _event: SelectTabEvent<HTMLElement>,
    data: SelectTabData
  ): void => {
    setSelectedKey(data.value as TabValue);
    GetUserProcess({
      action: ActionEnum.GetUserProcessRequest,
    });
  };
  const { isSocketReady } = useMailboxContent();
  const process_value = React.useMemo(() => {
    if (userOfflineProcess) {
      if (
        userOfflineProcess.oldest_online == userOfflineProcess.oldest_processed
      ) {
        return 1;
      } else {
        return (
          userOfflineProcess.email_processed / userOfflineProcess.email_total
        );
      }
    } else {
      return null;
    }
  }, [userOfflineProcess]);
  return (
    <div className={styles.container}>
      {userOfflineProcess ? (
        <div className={styles.statusCnt}>
          <BackgroundStatus />
          <Tooltip
            content={
              process_value == 1
                ? `Indexing emails is finished up to ${convertDate(userOfflineProcess?.oldest_processed)}`
                : userOfflineProcess?.oldest_processed != null
                  ? `Indexing emails in progress, currently up to ${convertDate(userOfflineProcess?.oldest_processed)}`
                  : 'Indexing emails pending'
            } // Tooltip string
            relationship="label" // Used for accessibility, indicates the element is a label
          >
            <div className={styles.barCnt}>
              <BatteryProgressBar
                value={process_value}
                width={'200px'}
                height={5}
              />
            </div>
          </Tooltip>
        </div>
      ) : (
        <BackgroundStatus />
      )}

      <TabList
        defaultSelectedValue={TabValue.MatterHistory}
        size="small"
        className={styles.tabList}
        onTabSelect={(event, data) => handleTabChange(event, data)}
      >
        {Object.entries(tabContent).map(([key, { icon, displayName }]) => (
          <Tab
            icon={React.cloneElement(icon, {
              className:
                selectedKey === key ? styles.selectedTabIcon : styles.tabIcon,
            })}
            key={key}
            value={key as TabValue}
          >
            <div
              className={`${selectedKey === key ? styles.selectedTabLabel : styles.tabLabel}`}
            >
              {displayName}
            </div>
          </Tab>
        ))}
      </TabList>
      <div
        className={styles.socketStatus}
        title={isSocketReady ? 'Connected' : 'Not Connected'}
      >
        <span
          style={{
            display: 'inline-block',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: isSocketReady ? 'green' : 'red',
          }}
        />
      </div>
      <div className={styles.tabContent}>
        {selectedKey === TabValue.MatterHistory && <MatterHistoryTab />}
        {selectedKey === TabValue.EmailDocs && <EmailDocsTab />}
        {selectedKey === TabValue.MainHome && <MainHomeTab />}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  barCnt: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  container: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  statusCnt: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5px',
    paddingRight: '5px',
    marginTop: '8px',
  },
  socketStatus: {
    position: 'absolute',
    top: '0px',
    right: '6px',
    zIndex: 999,
  },
  tabList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: '9px',
    paddingBottom: '12px',
    backgroundColor: '#fff',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#ccc',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.07)',
  },
  tabIcon: {
    color: '#3B3B3B',
  },
  selectedTabIcon: {
    color: '#0387f5',
  },
  selectedTabLabel: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#0387f5',
  },
  tabLabel: {
    fontSize: '12px',
    color: '#3B3B3B',
  },
  tabContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    backgroundColor: 'white',
  },
  button: {
    marginTop: '10px',
  },
});
