import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

// Import basic language support
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx';
import javascript from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import typescript from 'react-syntax-highlighter/dist/esm/languages/prism/typescript';

// Register the languages
SyntaxHighlighter.registerLanguage('jsx', jsx);
SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('typescript', typescript);

import {
  Button,
  makeStyles,
  shorthands,
  Textarea,
} from '@fluentui/react-components';

import { useMailboxContent } from '../../../provider/MailboxContentProvider';

import { SummaryContainer } from '../matterHistory/SummaryContainer';

import {
  ExpandUpRight24Regular,
  ThumbLike24Regular,
  ThumbDislike24Regular,
} from '@fluentui/react-icons';

import { UserFeedbackRequest } from '../../../model/feedback';
import { ActionEnum } from '../../../model/base';
import { useMediaQuery } from 'react-responsive';
import { SummaryStatusEnum } from '../../../model/summary';
import { getStylesForDevice } from '../../../helper/getStylesForDevice';
import { Spinner } from '@fluentui/react-components';
import ReactDOM from 'react-dom';

export const MatterHistoryTab: React.FC = () => {
  const styles = useStyles();

  const {
    currentMessageSummary,
    featureRefId,
    traceId,
    socketRef,
    isSocketReady,
    isSummaryComplete,
    GetUserProcess,
  } = useMailboxContent();

  const showFeedbackButtons =
    currentMessageSummary?.status === SummaryStatusEnum.Completed;
  const [isLikeModalOpen, setIsLikeModalOpen] = React.useState(false);
  const [isDislikeModalOpen, setIsDislikeModalOpen] = React.useState(false);
  const [feedbackContent, setFeedbackContent] = React.useState<string>('');
  const dialogHandler = React.useRef(null);
  const [popupWindow, setPopupWindow] = React.useState<Window | null>(null);

  const handleDisplayPopupWindow = () => {
    if (
      currentMessageSummary === undefined ||
      currentMessageSummary.status === SummaryStatusEnum.InProgress
    )
      return;

    // Close existing popup if open
    if (popupWindow && !popupWindow.closed) {
      popupWindow.close();
    }

    // Open new popup window
    const newWindow = window.open(
      '',
      'SummaryPopup',
      'width=800,height=600,resizable=yes,scrollbars=yes'
    );

    if (newWindow) {
      setPopupWindow(newWindow);

      // Write initial content to the new window
      newWindow.document.write(`
        <html>
          <head>
            <title>Matter History</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
                color: #333;
                max-width: 800px;
                margin: 0 auto;
                padding: 20px;
              }
              table {
                border-collapse: collapse;
                width: 100%;
                margin-bottom: 1rem;
              }
              th, td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
              pre {
                background-color: #f5f5f5;
                padding: 1em;
                overflow-x: auto;
              }
            </style>
          </head>
          <body>
            <div id="root"></div>
          </body>
        </html>
      `);

      // Render the ReactMarkdown in the popup
      ReactDOM.render(
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            code({
              node,
              inline,
              className,
              children,
              ...props
            }: {
              node?: any;
              inline?: boolean;
              className?: string;
              children: React.ReactNode;
            }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  style={vscDarkPlus}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                  loading={() => <div>Loading syntax...</div>}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            // Custom rendering for tables to ensure they're responsive
            table: ({ children, ...props }) => (
              <div style={{ overflowX: 'auto' }}>
                <table {...props}>{children}</table>
              </div>
            ),
          }}
        >
          {currentMessageSummary.summaryParagraph}
        </ReactMarkdown>,
        newWindow.document.getElementById('root')
      );

      // Add event listener to close React app when window closes
      newWindow.addEventListener('unload', () => {
        ReactDOM.unmountComponentAtNode(
          newWindow.document.getElementById('root')
        );
      });
    } else {
      console.error(
        'Failed to open popup window. It may have been blocked by the browser.'
      );
    }
  };

  React.useEffect(() => {
    return () => {
      console.log('Close Matter History');
      if (popupWindow && !popupWindow.closed) {
        popupWindow.close();
      }
    };
  }, [popupWindow]);

  React.useEffect(() => {
    return () => {
      console.log('Close Matter History');
      if (dialogHandler.current) {
        dialogHandler.current.close();
      }
    };
  }, []);

  const handleDisplayLikeModal = () => {
    setIsLikeModalOpen(true);
  };

  const handleDisplayDislikeModal = () => {
    setIsDislikeModalOpen(true);
  };

  const sendFeedbackGet = (feedbackType: 'thumbs_up' | 'thumbs_down') => {
    const request: UserFeedbackRequest = {
      action: ActionEnum.UserFeedbackRequest,
      feature_type: 'MatterHistory',
      feature_ref_id: featureRefId,
      feedback: feedbackType,
      trace_id: traceId,
      note: feedbackContent,
    };

    console.log('Sending feedback', request);
    if (socketRef && socketRef.readyState === WebSocket.OPEN) {
      socketRef.send(JSON.stringify(request));
    } else {
      console.error('WebSocket is not open. Unable to send feedback.');
    }

    // Close the modal after sending feedback
    setIsLikeModalOpen(false);
    setIsDislikeModalOpen(false);

    // Clear the feedback content
    setFeedbackContent('');

    console.log('Sent FeedbackGetRequest to server', request);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
    feedbackType: 'thumbs_up' | 'thumbs_down'
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendFeedbackGet(feedbackType);
    }
  };

  const isDesktop1 = useMediaQuery({
    maxWidth: '1366px',
    maxHeight: '768px',
  });
  const isDesktop2 = useMediaQuery({
    maxHeight: '1920px',
    maxWidth: '1080px',
  });
  const isMacBook13 = useMediaQuery({
    maxWidth: '2560px',
    maxHeight: '1600px',
  });
  const isiPadPro = useMediaQuery({
    maxWidth: '2048px',
    maxHeight: '2732px',
  });

  const deviceType = React.useMemo(() => {
    if (isDesktop1) return 'Desktop1';
    if (isDesktop2) return 'Desktop2';
    if (isMacBook13) return 'MacBook13';
    if (isiPadPro) return 'iPadPro';
    return null;
  }, [isDesktop1, isDesktop2, isMacBook13, isiPadPro]);

  const deviceStyles = React.useMemo(() => {
    return deviceType ? getStylesForDevice(deviceType) : {};
  }, [deviceType]);

  return (
    <>
      <div className={styles.popupButton}>
        <div className={styles.tabContentHeader}></div>
        {!isSummaryComplete && isSocketReady ? (
          <>
            <div className={styles.spinnerContainer}>
              <Spinner size="small" />
            </div>
          </>
        ) : null}
        {currentMessageSummary?.status === SummaryStatusEnum.Completed && (
          <>
            <ExpandUpRight24Regular
              className={styles.popupIcon}
              onClick={handleDisplayPopupWindow}
            />
            <ThumbLike24Regular
              className={styles.likeIcon}
              onClick={handleDisplayLikeModal}
            />
            <ThumbDislike24Regular
              className={styles.dislikeIcon}
              onClick={handleDisplayDislikeModal}
            />
          </>
        )}
      </div>
      {currentMessageSummary?.status === SummaryStatusEnum.Completed ? (
        <div className={styles.container}>
          <SummaryContainer currentMessageSummary={currentMessageSummary} />
        </div>
      ) : null}

      {isLikeModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <h2>Please tell us what you like about it</h2>
            <Textarea
              className={styles.textArea}
              style={deviceStyles.textArea}
              placeholder="Write here ..."
              onChange={e => {
                setFeedbackContent(e.target.value);
              }}
              onKeyDown={e => handleKeyDown(e, 'thumbs_up')}
            ></Textarea>
            <div className={styles.modalActions}>
              <Button
                className={styles.notNowButton}
                style={deviceStyles.button}
                onClick={() => setIsLikeModalOpen(false)}
              >
                Not Now
              </Button>
              <Button
                className={styles.sendButton}
                style={deviceStyles.button}
                onClick={() => sendFeedbackGet('thumbs_up')}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      )}

      {isDislikeModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <h2>Please tell us what is wrong that you don't like.</h2>
            <Textarea
              className={styles.textArea}
              style={deviceStyles.textArea}
              placeholder="Write here ..."
              onChange={e => {
                setFeedbackContent(e.target.value);
              }}
              onKeyDown={e => handleKeyDown(e, 'thumbs_down')}
            ></Textarea>
            <div className={styles.modalActions}>
              <Button
                className={styles.notNowButton}
                style={deviceStyles.button}
                onClick={() => setIsDislikeModalOpen(false)}
              >
                Not Now
              </Button>
              <Button
                className={styles.sendButton}
                style={deviceStyles.button}
                onClick={() => sendFeedbackGet('thumbs_down')}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  popupButton: {
    position: 'relative',
    top: 0,
    left: '0px',
  },
  popupIcon: {
    position: 'absolute',
    top: '10px',
    right: '80px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  disabledpopupIcon: {
    cursor: 'not-allowed',
    position: 'absolute',
    top: '10px',
    right: '80px',
    marginBottom: '20px',
  },
  likeIcon: {
    position: 'absolute',
    top: '10px',
    right: '48px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  dislikeIcon: {
    position: 'absolute',
    top: '10px',
    right: '20px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: '#fff',
    ...shorthands.padding('20px'),
    ...shorthands.borderRadius('8px'),
    width: '400px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
  },
  textArea: {
    width: '100%',
    height: '100px',
    marginTop: '10px',
    ...shorthands.padding('10px'),
    ...shorthands.borderRadius('4px'),
    ...shorthands.border('1px', 'solid', '#ccc'),
    fontSize: '14px',
  },
  modalActions: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '180px',
    float: 'right',
  },
  notNowButton: {
    backgroundColor: 'transparent',
    ...shorthands.border('1px', 'solid', '#ccc'),
    ...shorthands.borderRadius('4px'),
    ...shorthands.padding('10px', '20px'),
    cursor: 'pointer',
    fontSize: '14px',
  },
  sendButton: {
    backgroundColor: '#0078d4',
    color: '#fff',
    ...shorthands.border('none'),
    ...shorthands.borderRadius('4px'),
    ...shorthands.padding('10px', '20px'),
    cursor: 'pointer',
    fontSize: '14px',
  },
  tabContentHeader: {
    height: '10px',
    fontSize: '21px',
    paddingLeft: '15px',
    fontWeight: 'bold',
    marginBottom: '30px',
  },
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '12px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  tabContentHeaderBottom: {
    height: '1px',
    width: '100%',
    backgroundColor: '#ccc',
  },
});
